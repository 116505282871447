import './init'
import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { forkJoin, from, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import pkg from '../package.json'
import api from './api'
import { client_id as demoClientId } from './api/demo'
import App from './App'
import { joinChannel } from './broadcast'
import { ModalsProvider } from './hooks/useModals'
import { NotificationsProvider } from './hooks/useNotifications'
import { UserProvider, updateUser } from './hooks/useUser'
import { getInstallType, siteSetup } from './utils/site'

siteSetup()

if (getInstallType() === 'prod') {
  Sentry.init({
    dsn: 'https://08826e78e99049c6a1b4c02737ecd41d@o433246.ingest.sentry.io/5387982',
    ignoreErrors: [
      'Error: Faulty X-Revision value provided',
      'Error: Login token has expired!',
      'Error: No token found!',
      'Error: Provided token is expired.',
      'Error: Unauthorized.',
      'Error: User or password is wrong.',
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded',
      `SyntaxError: Unexpected token '<'`,
      `SyntaxError: expected expression, got '<'`,
      'TypeError: Abgebrochen',
      'TypeError: Cancelled',
      'TypeError: Die Netzwerkverbindung wurde unterbrochen.',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.'
    ],
    release: `${pkg.version}${
      import.meta.env.REACT_APP_CURRENT_REV
        ? `@${import.meta.env.REACT_APP_CURRENT_REV}`
        : ''
    }`
  })
}

let isSuperadmin

const renderApp = () => {
  const container = window.document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <Suspense
      fallback={
        <div
          style={{ color: 'lightgray', fontFamily: 'monospace', padding: 16 }}
        >
          Initializing…
        </div>
      }
    >
      <UserProvider>
        <ModalsProvider>
          <NotificationsProvider>
            <App />
          </NotificationsProvider>
        </ModalsProvider>
      </UserProvider>
    </Suspense>
  )
}

const init = () =>
  from(window.caches?.keys() || of([]))
    .pipe(
      switchMap((cacheNames) =>
        // Try to authenticate and purge api cache
        forkJoin([
          api.authentication.authenticate$(),
          Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)))
        ])
      ),
      catchError((error) => {
        // Catch cache erros
        console.debug(error.message)
        return of([])
      }),
      switchMap(([session]) => {
        if (session) {
          isSuperadmin = session.sub.superadmin

          // Store session data in global state
          updateUser({ session })

          // Join broadcast channel
          joinChannel()

          // Fetch user settings and datasources
          return forkJoin([
            api.usersettings.find$(
              { limit: null, skip: null },
              { id: session.sub.id }
            ),
            api.datasources.findAll$(undefined, undefined, [
              'emmamembertableschema'
            ])
          ])
        }

        // Just emit empty response if no session was established
        return of([{}, {}])
      }),
      switchMap(([usersettings, datasources]) =>
        forkJoin([
          of(usersettings),
          of(datasources),
          usersettings.data?.[0]
            ? api.clients.findOne$(usersettings.data[0].client_id)
            : of({})
        ])
      )
    )
    .subscribe(
      ([usersettings, datasources, client]) => {
        if (!isSuperadmin && client?.client_id === demoClientId) {
          window.isDemo = true
        }

        // Store user settings in global state
        updateUser({
          client,
          settings: usersettings.data?.[0],
          datasources: datasources.data
        })

        // Initialize app with appropriate theme
        switch (window.location.hostname) {
          case 'ems-dev.enimail.de':
          case 'ems.enimail.de':
          case 'enimail.preview.mundpropaganda.net':
            import('./scss/enimail.scss').then(() => {
              document.body.classList.add('enimail')
              renderApp()
            })
            break
          default:
            renderApp()
        }
      },
      (error) => {
        // Catch and display init errors
        console.error(error)

        // Remove jwt so user doesn't get stuck in broken session
        localStorage.removeItem('loginToken')

        const el = document.createElement('div')
        el.classList.add('p-3', 'text-black-50', 'text-monospace')
        el.textContent = `Error during initialization: ${error.message}`
        document.body.append(el)
      }
    )

init()
