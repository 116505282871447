import dayjs from 'dayjs'
import { validate as validateEmail } from 'email-validator'
import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import _isNull from 'lodash/isNull'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _set from 'lodash/set'
import punycode from 'punycode'
import requirements from './requirements'

const rDigit = /^\d+$/
const rDomain = /^((?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/
const rIp = /^(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/

const isAscii = (str) => {
  for (let i = 0, len = str.length; i < len; i++) {
    if (str.charCodeAt(i) > 127) return false
  }
  return true
}

export const compareArrays = (set1, set2) =>
  !set1 || !set2
    ? false
    : set1.every((id) => set2.includes(id)) &&
      set2.every((id) => set1.includes(id))

export const decodeHtmlEntities = (str) => {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = str
  return textarea.value
}

export const fixObjValueTypes = (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      rDigit.test(value) ? Number(value) : value
    ])
  )

export const getDomainBlocklistInfo = (blocklist_info) => {
  let blocklistEntries = blocklist_info

  if (_isObject(blocklistEntries)) {
    blocklistEntries = Object.entries(blocklistEntries)
  }

  if (_isString(blocklistEntries) && blocklistEntries.startsWith('[')) {
    blocklistEntries = JSON.parse(blocklistEntries)
  }

  if (_isString(blocklistEntries) && blocklistEntries.startsWith('{')) {
    blocklistEntries = Object.entries(JSON.parse(blocklistEntries))
  }

  return blocklistEntries
}

export const getEnforceIpci = (state) => {
  const nextState = {}

  if (
    state.tests.dns.imap_login_id &&
    state.tests.dns.required_pass.length > 0
  ) {
    nextState.tests = {}
    nextState.tests.dns = state.tests.dns
  }

  if (state.tests.expurgate.imap_login_id) {
    if (!nextState.tests) {
      nextState.tests = {}
    }
    nextState.tests.expurgate = state.tests.expurgate
  }

  if (nextState.tests) {
    nextState.actions = state.actions
  }

  if (state.check_interval) {
    nextState.interval_type = state.interval_type
    nextState.check_interval =
      nextState.interval_type === 'num_mails'
        ? Number(state.check_interval)
        : `${state.check_interval} minutes`
  }

  return Object.keys(nextState).length > 0 ? nextState : null
}

export const getInitials = (fullName = '') =>
  fullName
    .split(' ')
    .flatMap((str) => str.split('-'))
    .map((str) => str.substring(0, 1).toUpperCase())
    .join('')

export const getParentAttrValue = (child, attr) => {
  if (!child.getAttribute) return null
  const value = child.getAttribute(attr)
  return value === null ? getParentAttrValue(child.parentNode, attr) : value
}

export const getPercentage = (a, b) =>
  guardNaN(Math.round((a / b) * 10000) / 100)

export const getUniqueList = (list = [], key) =>
  Array.isArray(list)
    ? list.flat().reduce((acc, item) => {
        const i = acc.findIndex((a) => a[key] === item[key])
        if (i === -1) {
          acc.push(item)
        }
        return acc
      }, [])
    : list === undefined
      ? []
      : [list]

export const getValue = (el, form) => {
  if (el.name.endsWith('_id')) {
    // biome-ignore lint/suspicious/noGlobalIsNan:
    return el.value && !isNaN(el.value) ? Number(el.value) : undefined
  }

  if (el.type === 'checkbox') {
    return el.checked
  }

  if (
    el.type === 'number' ||
    el.inputMode === 'numeric' ||
    el.dataset.type === 'formatted-number'
  ) {
    const value = el.value
      .replace(/[.,](\d{3})/g, '$1')
      .replace(/,(\d{1,2})$/, '.$1')

    // biome-ignore lint/suspicious/noGlobalIsNan:
    return value === '' ? null : isNaN(value) ? 0 : Number(value)
  }

  if (el.type === 'radio') {
    return form.elements[el.name].value
  }

  return el.value
}

// biome-ignore lint/suspicious/noGlobalIsNan:
export const guardNaN = (v) => (isNaN(v) || v <= 0 ? 0 : v)

export const getFormData = (form, item = {}, disallow = {}) => {
  const newState = { ...item }
  const fields = [...form].filter(
    (el) =>
      el.localName.match(/(input|select|textarea)/) && !el.disabled && el.name
  )

  for (const field of fields) {
    const key = field.name.replace(/\[\]$/, '')
    const arr = field.name.endsWith('[]') && _get(newState, key, [])
    let value = getValue(field, form)

    if (arr) {
      value = value === '' ? [] : [...new Set(arr).add(value)]
    }

    _set(newState, key, value)
  }

  for (const key of Object.keys(disallow)) {
    if (newState[key].match(disallow[key])) {
      throw new Error(`Invalid ${key}: ${disallow[key].toString()}`)
    }
  }

  return newState
}

export const getInputTypeValue = (type, value = '') => {
  switch (type) {
    case 'datetime':
      return {
        inputType: 'datetime-local',
        inputValue: value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss') : value
      }
    case 'decimal':
    case 'integer':
      return {
        inputType: 'number',
        inputValue: value
      }
    default:
      return {
        inputType: 'text',
        inputValue: value
      }
  }
}

export const getNestedOption = (options, val) => {
  for (const i in options) {
    if (options[i].options) {
      const opt = getNestedOption(options[i].options, val)
      if (opt) return opt
    } else {
      if (options[i].name === val) return options[i]
    }
  }
}

export const int2ip = (num) => {
  const parts = []
  for (let i = 0; i < 4; i++) {
    parts.unshift((num % 256).toString())
    num = Math.floor(num / 256)
  }
  return parts.join('.')
}

export const ip2int = (str) =>
  str.split('.').reduce((num, prt, i) => num + Number(prt) * 256 ** (3 - i), 0)

export const isDomain = (str) => rDomain.test(str)

export const isEmail = (str) => {
  if (!str || !str.includes('@')) return false
  if (isAscii(str)) return validateEmail(str)
  const [localpart, domain] = str.split('@')
  return validateEmail(`${localpart}@${punycode.encode(domain)}`)
}

export const isValidSqlValue = (value) =>
  ![
    ';',
    '--',
    '/\\*\\*/',
    'SELECT user',
    'SELECT current_user',
    'SELECT session_user',
    'SELECT usename FROM pg_user',
    'SELECT getpgusername()',
    'SELECT usename FROM pg_user',
    'SELECT usename FROM pg_user WHERE usesuper IS TRUE',
    'SELECT usename, usecreatedb, usesuper, usecatupd FROM pg_user',
    'SELECT current_database()',
    'SELECT datname FROM pg_database',
    'SELECT table_name FROM information_schema.tables',
    "SELECT column_name FROM information_schema.columns WHERE table_name='data_table'",
    "select query_to_xml('select * from pg_user',true,true,'')",
    "select database_to_xml(true,true,'')",
    "select database_to_xmlschema(true,true,'')",
    "' and substr(version(),1,10) = 'PostgreSQL' and '",
    "' and substr(version(),1,10) = 'PostgreXXX' and '",
    'AND [RANDNUM]=(SELECT [RANDNUM] FROM PG_SLEEP([SLEEPTIME]))',
    "select pg_ls_dir('./')",
    "select pg_read_file('PG_VERSION', 0, 200)",
    'CHR(65)',
    'CHR(66)',
    'CHR(67)',
    '$$'
  ].some((str) => value.includes(str))

export const isIp = (str) => rIp.test(str)

export const mergeData = (prevState, data, predicate = 'id') => {
  const nextState = [...prevState]
  for (const item of data) {
    const i = nextState.findIndex(
      (prevItem) => prevItem[predicate] === item[predicate]
    )

    if (i > -1) {
      nextState[i] = item
    } else {
      nextState.push(item)
    }
  }
  return nextState
}

export const parseTeststats = (tests, campagne_id) => {
  const data = []

  for (const { html } of tests) {
    const container = document.createElement('div')
    container.innerHTML = html

    const rows = container.querySelectorAll('tr')
    const header = rows[0].textContent.split('\n')

    for (let i = 1; i < rows.length; i++) {
      const cols = rows[i].textContent.split('\n')
      const row = []

      // First/last col is empty, ignoring both
      for (let j = 1; j < cols.length - 1; j++) {
        row.push([header[j], cols[j]])
      }
      row.push(['campagne_id', campagne_id])

      data.push(Object.fromEntries(row))
    }
  }

  return data
}

export const sanitizeRegexStr = (str) => str.replace(/[#-.]|[[-^]|[?|{}]/g, '')

export const sortAlphabetically = (criteria) => (a, b) => {
  a = _isObject(a) ? _get(a, criteria.key, '') : a
  b = _isObject(b) ? _get(b, criteria.key, '') : b

  a = _isNull(a) ? '' : a
  b = _isNull(b) ? '' : b

  let key1 = criteria.desc ? b : a
  let key2 = criteria.desc ? a : b

  if (criteria.formatters?.[criteria.key]) {
    key1 = criteria.formatters[criteria.key](key1)
    key2 = criteria.formatters[criteria.key](key2)
  }

  if (_isBoolean(key1) && _isBoolean(key2)) {
    return key1 === key2 ? 0 : key1 ? -1 : 1
  }

  if (typeof key1 === 'string' || typeof key2 === 'string') {
    return String(key1).toLowerCase().localeCompare(String(key2).toLowerCase())
  }

  return key1 - key2
}

export const sortObj = (obj) =>
  Object.fromEntries(
    Object.entries(obj)
      .sort((a, b) => sortAlphabetically(a[0], b[0]))
      .map(([key, value]) => [key, _isObject(value) ? sortObj(value) : value])
  )

export const validateItem = ({ data, service, t = (str) => str }) => {
  const required = requirements(service, t)

  const filterFn = (a) => {
    if (a.$or) {
      return a.$or.every(filterFn)
    }

    const key = a.key || a

    if (Array.isArray(_get(data, key))) {
      return _get(data, key).length === 0
    }

    return ['', null, undefined].includes(_get(data, key))
  }

  const errors = required.filter(filterFn)

  if (errors.length === 0) {
    return data
  }

  return {
    errors: errors
      .map((e) =>
        e.$or
          ? e.$or
              .map((opt) => `"${opt.label || opt.key || opt}"`)
              .join(` ${t('misc.or')} `)
          : `"${e.label || e.key || e}"`
      )
      .join(', ')
  }
}
