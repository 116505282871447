import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { generateID } from 'react-querybuilder'

const ModalsContext = createContext()

const initialModals = []
export let addModals = (modals = []) => {
  for (const m of modals) {
    m.id ||= generateID()
  }
  initialModals.push(...modals)
  return modals.map((m) => m.id)
}
export let removeModals = (ids = []) => {
  for (const id of ids) {
    const i = initialModals.findIndex((m) => m.id === id)
    if (id > -1) {
      initialModals.splice(i, 1)
    }
  }
}

export function ModalsProvider({ children }) {
  const [modals, setModals] = useState(initialModals)

  const removeModal = useCallback(
    (id) =>
      setModals((prevState) =>
        id ? prevState.filter((m) => m.id !== id) : []
      ),
    []
  )

  const addModal = useCallback((modal = {}) => {
    modal.id = modal.id?.toString?.() ?? generateID()

    setModals((prevState) => {
      const nextState = [...prevState]
      const i = nextState.findIndex((n) => n.id === modal.id)

      if (i > -1) {
        nextState[i] = modal
        return nextState
      }

      nextState.push({ actions: [], ...modal })
      return nextState
    })

    return modal.id
  }, [])

  useEffect(() => {
    addModals = (n) => n.map(addModal)
    removeModals = (n) => n.map(removeModal)
    return () => {
      addModals = undefined
      removeModals = undefined
    }
  }, [addModal, removeModal])

  return (
    <ModalsContext.Provider
      value={{
        addModal,
        modals,
        removeModal
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export default function useModals() {
  return useContext(ModalsContext)
}
