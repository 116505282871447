import i18n from '../i18n'
import { getTestSubscriberTypes } from '../utils/subscribers'
import brmAdapter from './brm'
import brmAuthentication from './brm/authentication'
import beeauthAdapter from './brm/beeauth'
import cloudstorageAdapter from './cloudstorage'
import couchdbAdapter from './couchdb'
import {
  allowedEndpoints,
  localFetch,
  mockEndpoint,
  mockedMethods
} from './demo'
import downloadsAdapter from './emma-downloads'
import expurgateNotificationsAdapter from './expurgate-notifications'
import gtranslateAdapter from './gtranslate'
import wordpressAdapter from './wordpress'

// Don't forget to add obfuscate config and permissions when adding new
// service!

window.windowFetch = window.fetch

window.fetch = async (...args) => {
  const req =
    typeof args[0] === 'string'
      ? { url: args[0], method: 'GET', ...(args[1] ?? {}) }
      : args[0]

  if (
    window.isDemo &&
    mockedMethods.includes(req.method) &&
    !mockEndpoint(req.url) &&
    !allowedEndpoints.some((regex) => regex.test(req.url))
  ) {
    console.debug('Unsupported', req)
    throw new Error(i18n?.t('errors.not_supported_in_demo'))
  }

  let res
  if (
    window.isDemo &&
    mockEndpoint(req.url) &&
    !allowedEndpoints.some((regex) => regex.test(req.url))
  ) {
    res = await localFetch(req)
  } else {
    res = await window.windowFetch(...args)
  }

  return res
}

const api = {
  agbs: brmAdapter('/v2/agb/', { sort: { key: 'created_at', desc: true } }),
  agbconfirms: brmAdapter('/v2/emma/client/{client_id}/agb-confirm/'),
  agbslatest: brmAdapter('/v2/agb:latest/{client_id}'),
  alerts: couchdbAdapter('alerts'),
  apphostips: brmAdapter('/mta/{host_name}/ip/'),
  apphostaction: brmAdapter('/apphost/{id}/{action}'),
  apphosts: brmAdapter('/apphost/', {
    jsonapi: true,
    label: 'host_name'
  }),
  authentication: brmAuthentication,
  bavs: brmAdapter('/v2/bav/', { sort: { key: 'created_at', desc: true } }),
  bavconfirms: brmAdapter('/v2/emma/client/{client_id}/bav-confirm/'),
  bavslatest: brmAdapter('/v2/bav:latest/{client_id}'),
  beeauth: beeauthAdapter,
  beerows: brmAdapter('/beeplugin/row/'),
  beecategories: brmAdapter('/catalog/categories/'),
  beecollections: brmAdapter('/catalog/collections/'),
  beetemplates: brmAdapter('/catalog/templates/'),
  blacklistscampaign: brmAdapter('/emma/multiblacklist/', {
    jsonapi: true,
    label: 'flag',
    primaryKey: 'upload_id'
  }),
  blacklistsglobal: brmAdapter('/emma/blacklist/', {
    label: 'email',
    primaryKey: 'blacklist_id'
  }),
  blacklistsinternal: brmAdapter('/allopeners/', {
    label: 'email',
    primaryKey: 'email'
  }),
  blacklistsinternalexcludes: brmAdapter('/blocklistexcludeentry/', {
    label: 'email'
  }),
  blacklistsmultiline: brmAdapter('/emma/tools/multilineblacklist/'),
  blackliststatus: brmAdapter('/emma/tools/checkblackliststatus/'),
  campaigns: brmAdapter('/emma/campaign/', {
    filters: { esp_id: [0] },
    jsonapi: true,
    primaryKey: 'campagne_id'
  }),
  campaignsv2: brmAdapter('/v2/emma/client/{client_id}/campaign/', {
    filters: { esp_id: [0] },
    jsonapi: true,
    primaryKey: 'campagne_id'
  }),
  campaignaction: brmAdapter('/emma/campaign/{id}/{action}'),
  campaignpresets: brmAdapter('/campaignpreset/', { label: 'name' }),
  campaigntest: brmAdapter('/emma/testcampaign/'),
  cappings: brmAdapter('/emma/capping/'),
  categories: brmAdapter('/emma/category/', {
    label: 'category_value'
  }),
  changelogs: wordpressAdapter('/changelog-{language}', {
    sort: { key: 'modified', desc: true }
  }),
  clickreport: brmAdapter(
    '/v2/emma/client/{client_id}/campaign/{campaign_id}/click-report',
    { sort: { key: 'ordre', desc: false } }
  ),
  clickreportsalesview: brmAdapter('/v2/salesview/{campaign_id}/click-report', {
    sort: { key: 'ordre', desc: false }
  }),
  clientapphost: brmAdapter('/emma/client/{id}/apphost'),
  clients: brmAdapter('/emma/client/', {
    label: (item) => `${item.client_name} (${item.name})`,
    primaryKey: 'client_id'
  }),
  cloudstorage: cloudstorageAdapter,
  clustermailclasses: brmAdapter('/clustermailclass/', {
    jsonapi: true
  }),
  cmuhistory: brmAdapter(
    '/v2/emma/client/{client_id}/campaign/{campaign_id}/c-m-u-history',
    { label: 'startdate', primaryKey: 'startdate' }
  ),
  dashboardview: brmAdapter('/view/dashboard/{type}/'),
  dashboards: brmAdapter('/dashboard/'),
  datasources: brmAdapter('/emma/membertable/', {
    jsonapi: true,
    label: 'table_alias',
    primaryKey: 'member_table_id',
    sort: { key: 'table_alias', desc: false }
  }),
  dataupload: brmAdapter('/dataupload/', { jsonapi: true }),
  datauploadv2: brmAdapter('/v2/dataupload/', { multipart: true }),
  datauploaderrors: brmAdapter('/dataupload/{id}/failed'),
  datauploadquota: brmAdapter('/v2/dataupload:quota'),
  domainmailboxes: brmAdapter(
    '/v2/emma/client/{client_id}/domain/{id}/mailbox/'
  ),
  domains: brmAdapter('/emma/domain/', {
    label: 'domain',
    primaryKey: 'domain_id'
  }),
  domainsv2: brmAdapter('/v2/emma/client/{client_id}/domain/', {
    label: 'domain',
    primaryKey: 'domain_id'
  }),
  domainscheckblocklist: brmAdapter(
    '/v2/emma/client/{client_id}/domain:checkblocklist/',
    { label: 'domain', primaryKey: 'domain_id' }
  ),
  domainsetup: brmAdapter('/setup/domain/', { label: 'domain' }),
  downloads: downloadsAdapter('/downloads/', {
    sort: { key: 'created_at', desc: true }
  }),
  downloadfiles: downloadsAdapter('/downloads/{id}/file'),
  downloadlogs: downloadsAdapter('/downloads/{id}/logs'),
  downloadrecurring: downloadsAdapter('/recurring/', {
    sort: { key: 'created_at', desc: true }
  }),
  downloadtemplates: downloadsAdapter('/templates/'),
  espreplacers: brmAdapter('/emma/{esp}/replacer/', {
    primaryKey: 'replacer_id'
  }),
  espsenders: brmAdapter('/emma/{esp}/sendfromid/', {
    label: 'from_address',
    primaryKey: 'sendfrom_id'
  }),
  espsettings: brmAdapter('/emma/{esp}/login/', {
    primaryKey: 'member_table_id'
  }),
  expurgatealerts: expurgateNotificationsAdapter('/clients/'),
  gahealth: brmAdapter('/greenarrow/health/'),
  gahealthcommand: brmAdapter('/greenarrow/health/{id}/{command}'),
  gtranslate: gtranslateAdapter(),
  guidedtours: brmAdapter('/v2/guided-tour/', { label: 'title' }),
  help: wordpressAdapter('/emmahelp-{language}', {
    sort: { key: 'modified', desc: true }
  }),
  helptags: wordpressAdapter('/helptag-{language}', {
    label: (item) => `${item.name} [${item.count}]`,
    sort: { key: 'name', desc: false }
  }),
  htmlbody: brmAdapter('/tools/htmlbody/'),
  imapsettings: brmAdapter('/emma/imap/setting/', {
    label: 'imap_host',
    primaryKey: 'setting_id'
  }),
  ipallocation: brmAdapter('/mtaip2emmaclient/'),
  ipallocationupdate: brmAdapter(
    '/v2/mtaip-to-emmaclient/force-update-aliases'
  ),
  ipallocationv2: brmAdapter('/v2/emma/client/{client_id}/mtaip-to-emmaclient'),
  ipcicampaigns: brmAdapter('/emma/ipci/campaign/', {
    jsonapi: true,
    primaryKey: 'entry_id'
  }),
  ipciresults: brmAdapter('/emma/ipci/result/', {
    jsonapi: true,
    primaryKey: 'result_id'
  }),
  ipciresultstats: brmAdapter(
    '/v2/emma/client/{client_id}/membertable/{member_table_id}/ipci/result/dashboardview'
  ),
  ips: brmAdapter('/ipaddress/', { label: 'address' }),
  mailclasses: brmAdapter('/emma/mailclass/', {
    label: (item) => item.mailclass_alias ?? item.mailclass,
    primaryKey: 'mailclass_id'
  }),
  mailclassestype: brmAdapter(
    '/v2/emma/client/{client_id}/membertable/{member_table_id}/mailclass:{type}/',
    {
      label: (item) => item.mailclass_alias ?? item.mailclass,
      primaryKey: 'mailclass_id'
    }
  ),
  mailclassesv2: brmAdapter('/v2/emma/client/{client_id}/mailclass/', {
    label: (item) => item.mailclass_alias ?? item.mailclass,
    primaryKey: 'mailclass_id'
  }),
  messages: brmAdapter('/motd/'),
  mtareport: brmAdapter(
    '/v2/emma/client/{client_id}/campaign/{campaign_id}/mta-report/'
  ),
  mtastats: brmAdapter('/mtastats/{id}'),
  mtastatsdrilldown: brmAdapter('/mtastats/{id}/drilldown'),
  passwordreset: brmAdapter('/password-reset/'),
  permissions: brmAdapter('/permission/'),
  placeholders: brmAdapter('/emma/placeholder/', {
    jsonapi: true,
    primaryKey: 'module_id'
  }),
  pmtemplates: brmAdapter('/v2/emma/client/{client_id}/pmt/'),
  preblockreason: brmAdapter(
    '/v2/emma/client/{client_id}/campaign/{campaign_id}/preblock-reason',
    { jsonapi: true, sort: { key: 'member_id', desc: false } }
  ),
  providers: brmAdapter('/emma/provider/', { primaryKey: 'provider_id' }),
  reportgroups: brmAdapter('/reportinggroup/', { jsonapi: true }),
  reports: brmAdapter('/emma/report/', {
    jsonapi: true,
    primaryKey: 'campagne_id',
    sort: { key: 'startdate', desc: true }
  }),
  reportsdownload: brmAdapter('/emma/report/download/'),
  reportsview: brmAdapter(
    '/v2/emma/client/{client_id}/membertable/{member_table_id}/campaign/{campaign_id}/report:view'
  ),
  roles: brmAdapter('/role/', {
    jsonapi: true
  }),
  rolepermissions: brmAdapter('/role/{id}/permission', {
    jsonapi: true
  }),
  salesview: brmAdapter('/salesview/', { jsonapi: true }),
  scdrules: brmAdapter('/v2/scd-rule/'),
  segments: brmAdapter('/emma/list/', {
    filters: { visibility: [1] },
    jsonapi: true,
    primaryKey: 'list_id'
  }),
  segmentscount: brmAdapter(
    '/v2/emma/client/{client_id}/membertable/{member_table_id}/list:count'
  ),
  segmentsubscribers: brmAdapter('/emma/list/{id}/member/', {
    primaryKey: 'member_id',
    label: (item) =>
      item.firstname && item.lastname
        ? `${item.firstname} ${item.lastname} <${item.email}>`
        : item.email
  }),
  sendvolume: brmAdapter('/v2/emma/client/{client_id}/send-volume'),
  sqltemplates: brmAdapter('/list/template/'),
  subreports: brmAdapter('/emma/subreport/'),
  subreportslist: brmAdapter('/emma/subreport/{campaign_id}/list/'),
  subscriberhistory: brmAdapter('/emma/member/{id}/history', {
    primaryKey: 'campagne_id'
  }),
  subscribers: brmAdapter(
    '/v2/emma/client/{client_id}/membertable/{member_table_id}/member/',
    {
      primaryKey: 'member_id',
      label: (item) =>
        item.firstname && item.lastname
          ? `${item.firstname} ${item.lastname} <${item.email}>`
          : item.email
    }
  ),
  tableattributes: brmAdapter(
    '/v2/emma/client/{client_id}/download-job/table-attributes'
  ),
  templatepreview: brmAdapter(
    '/v2/emma/client/{client_id}/message/{id}/render/{member_id}'
  ),
  templates: brmAdapter('/emma/message/', {
    jsonapi: true,
    primaryKey: 'message_id'
  }),
  templatesubject: brmAdapter(
    '/v2/emma/client/{client_id}/message:generate-subject'
  ),
  templatetest: brmAdapter('/v2/emma/client/{client_id}/message:test'),
  templatetrack: brmAdapter('/emma/message/{id}/track'),
  templateuntrack: brmAdapter('/emma/message/{id}/untrack'),
  testgroups: brmAdapter('/testgroup/'),
  testmailboxenable: brmAdapter('/emma/tools/enableimaplogin/{id}'),
  testmailboxes: brmAdapter('/emma/imap/login/', {
    jsonapi: true,
    label: 'email',
    primaryKey: 'imap_login_id'
  }),
  testmtastats: brmAdapter('/testmtastats/{id}'),
  testsubscribers: brmAdapter('/emma/testaddress/', {
    label: ({ email, addr_type }) =>
      `${email} (${
        getTestSubscriberTypes((...args) => i18n.t(...args)).find(
          (type) => type.value === addr_type
        )?.label
      })`,
    primaryKey: 'member_id'
  }),
  transactionalreports: brmAdapter('/transactional-report/'),
  transactionaldoireports: brmAdapter(
    '/v2/emma/client/{client_id}/membertable/{member_table_id}/doi-report'
  ),
  transactionals: brmAdapter('/transactionalcampaign/'),
  translations: brmAdapter('/translation/'),
  userclients: brmAdapter('/user/{id}/client/{client_id}/'),
  users: brmAdapter('/user/', { label: 'user_name', jsonapi: true }),
  usersclientreset: brmAdapter('/user:unstuck/{id}'),
  usersettings: brmAdapter('/user/{id}/settings/'),
  validatecriteria: brmAdapter(
    '/v2/emma/client/{client_id}/download-job/validate-criteria'
  ),
  volumecalculator: brmAdapter('/volumecalculator'),
  webforms: brmAdapter('/webform/')
}

export default api
